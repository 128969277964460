import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import KeywordFinder from './Components/KeywordFinder/KeywordFinder';
import ExclusiveLicense from './Components/ExclusiveLicense/ExclusiveLicense';
import BeatPackLicense from './Components/BeatPackLicense/BeatPackLicense';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lease" element={<KeywordFinder />} />
          <Route path="/exclusive" element={<ExclusiveLicense />} />
          <Route path="/beatpack" element={<BeatPackLicense />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
