import React from 'react';
import './BeatPackLicense.css';

const BeatPackLicense = () => {
  return (
    <div className="container">
      <h1 className="title">Beat Pack License Generator</h1>
      <p>Under Construction.</p>
    </div>
  );
};

export default BeatPackLicense;
