import React, { useState, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './KeywordFinder.css';
import confetti from 'canvas-confetti';
import SignatureCanvas from 'react-signature-canvas';

const ContractGenerator = () => {
  const [step, setStep] = useState(1);
  const [logo, setLogo] = useState(null);
  const [beatInfo, setBeatInfo] = useState({
    name: '',
    leasePrice: '',
    copies: '',
    publishingRights: '',
  });
  const [producerInfo, setProducerInfo] = useState({
    legalName: '',
    producerName: '',
    address: '',
    state: '',
    country: 'United States of America',
  });
  const [artistInfo, setArtistInfo] = useState({
    legalName: '',
    stageName: '',
    address: '',
    country: 'United States of America',
  });
  const [agreed, setAgreed] = useState(false);
  const signatureCanvasRef = useRef(null);

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const date = new Date().toLocaleDateString();
    const signatureData = signatureCanvasRef.current.toDataURL();

    // Add logo
    if (logo) {
      doc.addImage(logo, 'PNG', 10, 10, 50, 20);
    }

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("License Agreement (NON-EXCLUSIVE RIGHTS)", 10, 40);
    doc.text("Sound Recording/BEATS", 10, 50);

    doc.setFont('helvetica', 'normal');
    doc.text(`THIS LICENCE AGREEMENT is made on ${date} ("Effective Date") by and between`, 10, 60);

    doc.setFont('helvetica', 'bold');
    doc.text(`${artistInfo.legalName}`, 10, 70);
    doc.setFont('helvetica', 'normal');
    doc.text(`(hereinafter referred to as the "Licensee") also, if applicable, professionally known as`, 10, 80);
    doc.setFont('helvetica', 'bold');
    doc.text(`${artistInfo.stageName}`, 10, 90);
    doc.setFont('helvetica', 'normal');
    doc.text(`, whose principal address is ${artistInfo.address}, ${artistInfo.country}, and`, 10, 100);

    doc.setFont('helvetica', 'bold');
    doc.text(`${producerInfo.legalName}`, 10, 110);
    doc.setFont('helvetica', 'normal');
    doc.text(`(hereinafter referred to as the "Licensor") also, if applicable, professionally known as`, 10, 120);
    doc.setFont('helvetica', 'bold');
    doc.text(`${producerInfo.producerName}`, 10, 130);
    doc.setFont('helvetica', 'normal');
    doc.text(`, whose principal address is ${producerInfo.address}, ${producerInfo.country}.`, 10, 140);

    doc.autoTable({
      startY: 150,
      headStyles: { fillColor: [66, 133, 244] },
      body: [
        [{ content: 'Master Use', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `The Licensor hereby grants to License a non-exclusive license (this "License") to record vocal synchronization to the Composition partly or in its entirety and substantially in its original form ("Master Recording").`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Mechanical Rights', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `The Licensor hereby grants to Licensee a non-exclusive license to use Master Recording in the reproduction, duplication, manufacture, and distribution of phonograph records, cassette tapes, compact disks, digital downloads, other miscellaneous audio and digital recordings, and any lifts and versions thereof (collectively, the "Recordings", and individually, a "Recording") worldwide for up to the pressing or selling a total of ${beatInfo.copies} copies of such Recordings or any combination of such Recordings, condition upon the payment to the Licensor a sum of ${beatInfo.leasePrice} US dollars, receipt of which is confirmed.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Performance Rights', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `The Licensor hereby grants to Licensee a non-exclusive license to use the Master Recording in unlimited non-profit performances, shows, or concerts. Licensee may not receive compensation from performances with this license.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Synchronization Rights', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `The Licensor hereby grants limited synchronization rights for one (1) music video streamed online (Youtube, Vimeo, etc..) for up to 500,000 streams total on all websites. A separate synchronization license will need to be purchased for distribution of video to Television, Film or Video game.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Broadcast Rights', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `The Licensor hereby grants to Licensee a non-exclusive license to broadcast or air the Master Recording in two (2) radio stations through two (2) station channels, respectively. The Licensee shall not be permitted to receive compensation for such broadcasting.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Credit', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `Licensee shall acknowledge the original authorship of the Composition appropriately and reasonably in all media and performance formats under the name "Cabo Beats" in writing where possible and vocally otherwise.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Consideration', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `In consideration for the rights granted under this agreement, Licensee shall pay to licensor the sum of ${beatInfo.leasePrice} US dollars and other good and valuable consideration, payable to "${producerInfo.legalName}", receipt of which is hereby acknowledged. If the Licensee fails to account to the Licensor, timely complete the payments provided for hereunder, or perform its other obligations hereunder, including having insufficient bank balance, the licensor shall have the right to terminate License upon written notice to the Licensee. Such termination shall render the recording, manufacture and/or distribution of Recordings for which monies have not been paid subject to and actionable infringements under applicable law, including, without limitation, the United States Copyright Act, as amended.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Delivery', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `The Composition shall be delivered via email to an email address that Licensee provided to Licensor. Licensee shall receive an email from containing an attachment or link from which they can download the Composition.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Indemnification', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `Accordingly, Licensee agrees to indemnify and hold Licensor harmless from and against any and all claims, losses, damages, costs, expenses, including, without limitation, reasonable attorney's fees, arising of or resulting from a claimed breach of any of Licensee's representations, warranties or agreements hereunder.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Audio Samples', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `3rd party sample clearance is the responsibility of the Licensee.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Miscellaneous', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `This license is non-transferable and is limited to the Composition specified above, does not convey or grant any right of public performance for profit, constitutes the entire agreement between the Licensor and the Licensee relating to the Composition, and shall be binding upon both the Licensor and the Licensee and their respective successors, assigns, and legal representatives.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Governing Law', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `This License is governed by and shall be construed under the law of the State of Wyoming, United States of America, without regard to the conflicts of laws principles thereof.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'Publishing', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{
          content: `${artistInfo.legalName}, owns ${beatInfo.publishingRights}% of publishing rights.`,
          styles: { fontStyle: 'normal' }
        }],
        [{
          content: `${producerInfo.legalName}, owns ${100 - beatInfo.publishingRights}% of publishing rights.`,
          styles: { fontStyle: 'normal' }
        }],
        [{ content: 'THE PARTIES HAVE DULY EXECUTED THIS AGREEMENT on the date first written above.', styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: 'Licensor:', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{ content: 'Date: ' + date, styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: 'Signature Below', styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: `${producerInfo.legalName} - Producer`, styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: 'Authorized Signing Officer', styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: 'Licensee:', styles: { halign: 'left', fontStyle: 'bold' } }],
        [{ content: 'Date: ' + date, styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: '_______________________________________', styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: `${artistInfo.legalName} - Artist`, styles: { halign: 'left', fontStyle: 'normal' } }],
        [{ content: 'Authorized Signing Officer', styles: { halign: 'left', fontStyle: 'normal' } }],
      ],
      styles: {
        fontSize: 12,
        valign: 'top',
        overflow: 'linebreak',
        cellWidth: 'wrap'
      },
      columnStyles: {
        0: { cellWidth: 'auto' }
      }
    });

    // Calculate position for signature
    const finalY = doc.autoTable.previous.finalY;
    if (signatureData) {
      doc.addImage(signatureData, 'PNG', 10, finalY + 20, 50, 20);
      doc.text("Producer Signature", 10, finalY + 50);
    }

    const fileName = `${beatInfo.name} Non Exclusive (Lease) License Agreement by ${producerInfo.producerName}.pdf`;
    doc.save(fileName);

    // Confetti effect
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const countries = [
    "Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", 
    "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", 
    "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bonaire", 
    "Bosnia & Herzegovina", "Botswana", "Brazil", "British Indian Ocean Ter", "Brunei", "Bulgaria", "Burkina Faso", 
    "Burundi", "Cambodia", "Cameroon", "Canada", "Canary Islands", "Cape Verde", "Cayman Islands", 
    "Central African Republic", "Chad", "Channel Islands", "Chile", "China", "Christmas Island", "Cocos Island", 
    "Colombia", "Comoros", "Congo", "Cook Islands", "Costa Rica", "Cote D'Ivoire", "Croatia", "Cuba", "Curacao", 
    "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", 
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", 
    "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Ter", "Gabon", "Gambia", 
    "Georgia", "Germany", "Ghana", "Gibraltar", "Great Britain", "Greece", "Greenland", "Grenada", "Guadeloupe", 
    "Guam", "Guatemala", "Guinea", "Guyana", "Haiti", "Hawaii", "Honduras", "Hong Kong", "Hungary", "Iceland", 
    "India", "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jordan", 
    "Kazakhstan", "Kenya", "Kiribati", "Kosovo", "Korea North", "Korea South", "Kuwait", "Kyrgyzstan", "Laos", 
    "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", 
    "Macedonia", "Madagascar", "Malaysia", "Malawi", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", 
    "Mauritania", "Mauritius", "Mayotte", "Mexico", "Midway Islands", "Moldova", "Monaco", "Mongolia", "Montserrat", 
    "Morocco", "Mozambique", "Myanmar", "Nambia", "Nauru", "Nepal", "Netherland Antilles", "Netherlands (Holland, Europe)", 
    "Nevis", "New Caledonia", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Norway", 
    "Oman", "Pakistan", "Palau Island", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", 
    "Pitcairn Island", "Poland", "Portugal", "Puerto Rico", "Qatar", "Republic of Montenegro", "Republic of Serbia", 
    "Reunion", "Romania", "Russia", "Rwanda", "St Barthelemy", "St Eustatius", "St Helena", "St Kitts-Nevis", "St Lucia", 
    "St Maarten", "St Pierre & Miquelon", "St Vincent & Grenadines", "Saipan", "Samoa", "Samoa American", "San Marino", 
    "Sao Tome & Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", 
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", 
    "Sweden", "Switzerland", "Syria", "Tahiti", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tokelau", 
    "Tonga", "Trinidad & Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks & Caicos Is", "Tuvalu", "Uganda", 
    "Ukraine", "United Arab Emirates", "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", 
    "Vatican City State", "Venezuela", "Vietnam", "Virgin Islands (Brit)", "Virgin Islands (USA)", "Wake Island", 
    "Wallis & Futana Is", "Yemen", "Zaire", "Zambia", "Zimbabwe"
  ];

  return (
    <div className="container">
      <h1 className="title">Lease Contract Generator</h1>
      <div className="tab-container">
        <div className={`tab ${step === 1 ? 'tab-active' : ''}`} onClick={() => setStep(1)}>1. Beat Information</div>
        <div className={`tab ${step === 2 ? 'tab-active' : ''}`} onClick={() => setStep(2)}>2. Producer Information</div>
        <div className={` tab ${step === 3 ? 'tab-active' : ''}`} onClick={() => setStep(3)}>3. Artist Information</div>
        <div className={`tab ${step === 4 ? 'tab-active' : ''}`} onClick={() => setStep(4)}>4. Download</div>
      </div>

      {step === 1 && (
        <div>
          <div className="form-group">
            <label className="label">What is your beat's name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="Trap Banger"
              value={beatInfo.name}
              onChange={(e) => setBeatInfo({ ...beatInfo, name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">How much did you lease your beat for? (US Dollars)</label>
            <input
              type="number"
              className="input-field"
              placeholder="50.00"
              value={beatInfo.leasePrice}
              onChange={(e) => setBeatInfo({ ...beatInfo, leasePrice: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">How many copies can the artist sell?</label>
            <input
              type="number"
              className="input-field"
              placeholder="2500"
              value={beatInfo.copies}
              onChange={(e) => setBeatInfo({ ...beatInfo, copies: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">How much publishing rights would you like to keep? (%)</label>
            <input
              type="number"
              className="input-field"
              placeholder="50"
              value={beatInfo.publishingRights}
              onChange={(e) => setBeatInfo({ ...beatInfo, publishingRights: e.target.value })}
            />
          </div>
          <div className="navigation">
            <button className="button" onClick={handleNextStep}>Next</button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          <div className="form-group">
            <label className="label">What is your legal name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="John Doe"
              value={producerInfo.legalName}
              onChange={(e) => setProducerInfo({ ...producerInfo, legalName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is your producer name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="DJ Awesome"
              value={producerInfo.producerName}
              onChange={(e) => setProducerInfo({ ...producerInfo, producerName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is your address?</label>
            <textarea
              className="address-field"
              placeholder="1234 Main St, Apt 101"
              value={producerInfo.address}
              onChange={(e) => setProducerInfo({ ...producerInfo, address: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is your state?</label>
            <input
              type="text"
              className="input-field"
              placeholder="California"
              value={producerInfo.state}
              onChange={(e) => setProducerInfo({ ...producerInfo, state: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Please select your country.</label>
            <select
              className="select-field"
              value={producerInfo.country}
              onChange={(e) => setProducerInfo({ ...producerInfo, country: e.target.value })}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div className="navigation">
            <button className="button" onClick={handlePrevStep}>Previous</button>
            <button className="button" onClick={handleNextStep}>Next</button>
          </div>
        </div>
      )}

      {step === 3 && (
        <div>
          <div className="form-group">
            <label className="label">What is the artist's legal name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="Jane Smith"
              value={artistInfo.legalName}
              onChange={(e) => setArtistInfo({ ...artistInfo, legalName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is the artist's stage name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="DJ Cool"
              value={artistInfo.stageName}
              onChange={(e) => setArtistInfo({ ...artistInfo, stageName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is the artist's address?</label>
            <textarea
              className="address-field"
              placeholder="5678 Broadway St, Apt 202"
              value={artistInfo.address}
              onChange={(e) => setArtistInfo({ ...artistInfo, address: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Please select the artist's country.</label>
            <select
              className="select-field"
              value={artistInfo.country}
              onChange={(e) => setArtistInfo({ ...artistInfo, country: e.target.value })}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div className="navigation">
            <button className="button" onClick={handlePrevStep}>Previous</button>
            <button className="button" onClick={handleNextStep}>Next</button>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="download-section">
          <div className="form-group">
            <label className="label">Upload Logo (Optional)</label>
            <input type="file" className="input-file" onChange={handleLogoUpload} />
          </div>
          <div className="disclaimer">
            <h3>Disclaimer</h3>
            <p>
            This agreement carries legal consequences; therefore, it is recommended to seek legal advice before proceeding. The provision of this agreement does not constitute legal advice. All content is provided for informational purposes only, and MIDIMONEY makes no claims regarding its accuracy, legality, or suitability. The site owner will not be held liable for any errors, omissions, or any loss or damages arising from the use of this agreement.
            </p>
          </div>
          <div className="agree-section">
            <input 
              type="checkbox" 
              id="agree" 
              checked={agreed} 
              onChange={(e) => setAgreed(e.target.checked)} 
            />
            <label htmlFor="agree">I agree.</label>
          </div>
          <div className="form-group signature-section">
            <label className="label">Signature</label>
            <SignatureCanvas ref={signatureCanvasRef} canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }} />
            <button className="button" onClick={clearSignature}>Clear</button>
          </div>
          <button className="button download-button" onClick={generatePDF} disabled={!agreed}>Download PDF</button>
          <div className="navigation">
            <button className="button" onClick={handlePrevStep}>Previous</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractGenerator;
